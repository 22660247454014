var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"w-75",attrs:{"fluid":""}},[_c('v-container',{staticClass:"d-flex justify-space-between white rounded-xl"},[_c('v-container',[_c('div',{staticClass:"d-flex justify-space-between"},[_c('div',[_c('a',{on:{"click":function($event){return _vm.$router.push({
                name: 'CategoryPage',
                params: { id: _vm.product.lvl1Id },
              })}}},[_vm._v(_vm._s(_vm.getOptionName(_vm.product.lvl1Id)))]),_c('span',{staticClass:"mx-2 primary--text"},[_vm._v(">")]),_c('a',{on:{"click":function($event){return _vm.$router.push({
                name: 'TypePage',
                params: { id: _vm.product.lvl2Id },
              })}}},[_vm._v(_vm._s(_vm.getOptionName(_vm.product.lvl2Id)))])]),_c('div',[_c('div',{staticClass:"text--secondary"},[_c('span',{staticClass:"mr-2"},[_c('i',{staticClass:"far fa-eye"})]),_c('span',[_vm._v(_vm._s(_vm.product.viewCount))])])])]),_c('div',{staticClass:"d-flex justify-center mx-2"},[_c('img',{staticClass:"image-view rounded-lg mx-0",attrs:{"src":_vm.getProductPictureUrlById(_vm.selectedImageId)}})]),_c('div',{staticClass:"d-flex flex-column"},_vm._l((_vm.images),function(image,idx){return _c('img',{key:`img-${image.id}`,staticClass:"image-preview rounded mx-0",attrs:{"src":_vm.getProductPictureUrlById(image.id)},on:{"click":function($event){return _vm.selectImage(idx)}}})}),0)]),_c('v-container',{staticClass:"d-flex flex-column w-50",attrs:{"fluid":""}},[_c('h3',{staticClass:"kh"},[_vm._v(_vm._s(_vm.product.title))]),_c('div',[_c('a',{on:{"click":function($event){return _vm.$router.push({
              name: 'StorePage',
              params: { id: _vm.product.storeId },
            })}}},[_vm._v("Visit "+_vm._s(_vm.product.storeName))])]),_c('v-divider'),_c('div',{staticClass:"d-flex align-center"},[_c('h3',{staticClass:"mr-2"},[_vm._v("Listed Price")]),_c('h3',[_c('span',{staticClass:"red--text"},[_vm._v("$ "+_vm._s(_vm.product.unitPrice))])])]),_c('div',{staticClass:"kh",domProps:{"innerHTML":_vm._s(_vm.product.description)}})],1),_c('v-container',{staticClass:"d-flex justify-center w-50",attrs:{"fluid":""}},[_c('v-card',{staticClass:"rounded-lg",attrs:{"elevation":"0","outlined":"","width":"300px"}},[_c('v-card-text',{staticClass:"d-flex flex-column"},[_c('div',{staticClass:"d-flex justify-space-between",attrs:{"fluid":""}},[(_vm.product.quantity > 5)?_c('h3',{staticClass:"green--text text--darken-1"},[_vm._v(" In Stock ")]):(_vm.product.quantity <= 5 && _vm.product.quantity > 0)?_c('h3',{staticClass:"orange--text text--darken-1"},[_vm._v(" Only Few in Stock ")]):(_vm.product.quantity == 0)?_c('h3',{staticClass:"red--text text--darken-1"},[_vm._v(" Out of Stock ")]):_vm._e(),_c('a',{on:{"click":function($event){return _vm.$router.push({
                  name: 'StorePage',
                  id: _vm.product.storeId,
                })}}},[_c('h3',[_vm._v(_vm._s(_vm.product.storeName))])])]),_c('p',[_vm._v(" Free delivery on orders "),_c('span',{staticClass:"font-weight-bold"},[_vm._v("over $50")])])]),_c('v-card-text',[_c('v-autocomplete',{attrs:{"rounded":"","dense":"","outlined":"","label":"Quantity","items":[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]},model:{value:(_vm.qty),callback:function ($$v) {_vm.qty=$$v},expression:"qty"}})],1),_c('v-card-actions',{staticClass:"d-flex justify-space-around"},[_c('v-btn',{attrs:{"color":"primary","rounded":"","depressed":"","disabled":_vm.buyLoading,"loading":_vm.addLoading},on:{"click":_vm.addToCart}},[_c('i',{staticClass:"fas fa-cart-plus mr-2"}),_vm._v(" Add to Cart ")]),_c('v-btn',{attrs:{"color":"warning","rounded":"","depressed":"","loading":_vm.buyLoading},on:{"click":_vm.buyNow}},[_c('i',{staticClass:"fas fa-forward mr-2"}),_vm._v(" Buy Now ")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }