<template>
  <v-app>
    <main-navbar></main-navbar>
    <!-- <secondary-navbar></secondary-navbar> -->
    <v-main class="blue-grey lighten-5">
      <router-view />
    </v-main>
    <main-footer></main-footer>
    <general-snackbar></general-snackbar>
  </v-app>
</template>

<script>
import MainNavbar from "./components/MainNavbar.vue";
import MainFooter from "./components/MainFooter.vue";
import backendService from "./services/backend-service";
import GeneralSnackbar from "./components/GeneralSnackbar.vue";
export default {
  name: "App",
  components: {
    "main-navbar": MainNavbar,
    "main-footer": MainFooter,
    "general-snackbar": GeneralSnackbar,
  },
  data: () => ({
    //
  }),
  mounted: function () {
    this.$store.dispatch("getDropdownToOptions");

    if (localStorage.getItem("sessionId")) {
      backendService.verifySession().then((response) => {
        localStorage.setItem("session", JSON.stringify(response.data));
        localStorage.setItem(
          "userInfo",
          JSON.stringify(response.data.userInfo)
        );
        localStorage.setItem("sessionId", response.data.id);
        this.$store.commit("setCurrentUser", response.data.userInfo);
        this.$store.commit("setCart", JSON.parse(localStorage.getItem("cart")));
        this.$store.commit(
          "setCartCount",
          parseInt(localStorage.getItem("cartCount"))
        );
        this.$store.dispatch("getStoresByUserId");
      });
    } else {
      localStorage.setItem("cart", JSON.stringify({}));
      localStorage.setItem("cartCount", 0);
    }
  },
};
</script>
<style>
.kh {
  font-family: "Battambang", cursive;
}

.v-main {
  font-family: "Ubuntu", sans-serif;
}

/* main {
    background-color: #f1f6f9;
} */
.bg-blue-1 {
  background-color: #212a3e;
}
.bg-blue-2 {
  background-color: #394867 !important;
}
.bg-blue-3 {
  background-color: #9ba4b5;
}
.bg-blue-4 {
  background-color: #f1f6f9;
}
.hot-blink {
  animation: blinker 1s step-start infinite;
}
.w-75 {
  width: 75% !important;
}
.w-50 {
  width: 50% !important;
}
.w-25 {
  width: 25% !important;
}
@keyframes blinker {
  50% {
    opacity: 0.5;
  }
}
</style>
