<template>
  <v-container fluid class="w-75">
    <v-card elevation="0" class="rounded-xl mb-2">
      <v-card-title>Order Details</v-card-title>
      <v-card-text class="d-flex justify-space-between border-bottom">
        <div>
          <span class="font-weight-bold">Order #</span>
          <span>
            <br />
            {{ order.id }}
          </span>
        </div>
        <div>
          <span class="font-weight-bold">Order Placed</span>
          <span>
            <br />
            {{ convertToDate(order.dateCreated) }}
          </span>
        </div>
        <div>
          <span class="font-weight-bold">Ship To</span>
          <span>
            <br />
            {{ order.deliveryAddress.address1 }}
          </span>
          <span>
            <br />
            {{ order.deliveryAddress.address2 }}
          </span>
        </div>

        <div>
          <div class="d-flex justify-space-between">
            <div>
              <div class="mr-2 font-weight-bold">Subtotal:</div>
              <div class="mr-2 font-weight-bold">Delivery Fee:</div>
              <div class="font-weight-bold mr-2">Total:</div>
            </div>
            <div>
              <div>
                <span class="float-right">{{ order.subtotal.toFixed(2) }}</span
                >$
              </div>
              <div>
                <span class="float-right"
                  >${{ order.deliveryFee.toFixed(2) }}</span
                >
              </div>
              <div>
                <span class="float-right primary--text"
                  >${{ (order.subtotal + order.deliveryFee).toFixed(2) }}</span
                >
              </div>
            </div>
          </div>
        </div>
      </v-card-text>
    </v-card>
    <v-card elevation="0" class="rounded-xl">
      <v-card-text>
        <v-container
          v-for="orderDetail in order.orderDetails"
          :key="`order-detail-${orderDetail.id}`"
          class="d-flex"
          fluid>
          <img
            class="image-preview mr-2"
            :src="getProductPictureUrlById(orderDetail.pictureId)" />

          <v-container fluid class="d-flex flex-column">
            <a
              @click="
                $router.push({
                  name: 'ProductDetail',
                  params: { id: orderDetail.productId },
                })
              ">
              {{ orderDetail.productTitle }}
            </a>
            <span class="red--text"> $ {{ orderDetail.unitPrice }} </span>
            <div>
              <span class="mr-2 font-weight-bold">Qty:</span
              ><span> {{ orderDetail.quantity }}</span>
            </div>
            <div>
              <h4>
                <span class="mr-1">From:</span>
                <span>
                  <a
                    @click="
                      $router.push({
                        name: 'StorePage',
                        id: orderDetail.storeId,
                      })
                    "
                    >{{ orderDetail.storeName }}</a
                  >
                </span>
              </h4>
            </div>
          </v-container>
        </v-container>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
import backendService from "../services/backend-service.js";
import generalMixin from "@/js/general-mixin";
export default {
  name: "OrderDetail",
  data() {
    return {
      order: {},
    };
  },
  mixins: [generalMixin],
  created: function () {
    this.getOrder();
  },
  computed: {},
  methods: {
    getProductPictureUrlById(imageId) {
      return backendService.getProductPictureUrlById(imageId);
    },
    getOrder() {
      backendService.getOrderById(this.$route.params.id).then((response) => {
        this.order = response.data;
      });
    },
    print() {
      this.printReceipt(this.order);
    },
  },
};
</script>
<style scoped>
.image-preview {
  object-fit: contain;
  image-rendering: smooth;
  width: 100px;
  height: 100px;
}
</style>
