<template>
  <v-container fluid>
    <v-container fluid class="d-flex justify-space-between">
      <v-tabs v-model="tab" color="blue" background-color="blue-grey lighten-5">
        <v-tab :value="1">Products</v-tab>
        <v-tab :value="3">Orders</v-tab>
      </v-tabs>
      <v-btn
        color="primary"
        depressed
        rounded
        @click="
          $router.push({ name: 'ProductForm', params: { storeId: store.id } })
        "
        ><i class="fas fa-plus mr-2"></i>Add Product</v-btn
      >
    </v-container>

    <v-tabs-items v-model="tab">
      <v-tab-item class="grey lighten-3">
        <v-container fluid class="d-flex justify-space-between">
          <filter-panel @handle-event="applyFilter"></filter-panel>
          <v-card class="rounded-xl overflow-y-auto" elevation="0" width="79%">
            <v-card-text>
              <v-container>
                <v-container
                  class="d-flex justify-space-between white rounded-xl mb-2"
                  v-for="product in products"
                  :key="product.id">
                  <product-card
                    showQty
                    :product="product"
                    :allow-add-to-cart="true"></product-card>
                  <v-container fluid class="my-0 py-0">
                    <v-simple-table height="250px">
                      <thead>
                        <tr>
                          <th class="text-left">Id</th>
                          <th class="text-left">Quantity</th>
                          <th class="text-left">Stock Price</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="stock in productIdToStocks[product.id]"
                          :key="`${product.id}-stock-${stock.id}`">
                          <td>{{ stock.id }}</td>
                          <td>{{ stock.quantity }}</td>
                          <td>${{ stock.stockPrice }}</td>
                        </tr>
                      </tbody>
                    </v-simple-table>
                  </v-container>

                  <v-container fluid class="d-flex flex-column w-25">
                    <v-btn
                      class="mb-2"
                      color="warning"
                      depressed
                      rounded
                      @click="
                        $router.push({
                          name: 'ProductUpdate',
                          params: { id: product.id },
                        })
                      ">
                      <i class="fas fa-pencil-alt mr-2"></i>
                      Update
                    </v-btn>
                    <v-btn
                      class="mb-2"
                      color="warning"
                      depressed
                      outlined
                      rounded
                      @click="addStock(product)">
                      <i class="fas fa-people-carry mr-2"></i>
                      Add Stock
                    </v-btn>
                    <v-btn
                      color="warning"
                      depressed
                      outlined
                      rounded
                      @click="getStocksByProductId(product.id)">
                      <i class="fas fa-download mr-2"></i>
                      Get Stock History
                    </v-btn>
                  </v-container>
                </v-container>
              </v-container>
            </v-card-text>
          </v-card>
        </v-container>
      </v-tab-item>
      <v-tab-item class="grey lighten-3">
        <store-order-list :store-id="store.id"></store-order-list>
      </v-tab-item>
    </v-tabs-items>

    <in-stock-dialog
      :p-product="selectedProduct"
      v-model="inStockDialog"
      @set-product="
        (val) => {
          selectedProduct.quantity = val.quantity;
        }
      "></in-stock-dialog>
    <v-overlay v-model="isLoading">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </v-overlay>
  </v-container>
</template>
<script>
import backendService from "@/services/backend-service";
import ProductCard from "@/components/ProductCard.vue";
import StoreOrderList from "@/components/StoreOrderList.vue";
import InStockDialog from "@/components/InStockDialog.vue";
import FilterPanel from "@/components/FilterPanel.vue";
import generalMixin from "@/js/general-mixin";
export default {
  name: "StoreManagement",
  mixins: [generalMixin],
  mounted: function () {
    this.store.id = this.$route.params.id;
    this.getStoreById(this.$route.params.id);
    this.getProductsByStoreId(this.$route.params.id);
  },
  components: {
    "store-order-list": StoreOrderList,
    "product-card": ProductCard,
    "in-stock-dialog": InStockDialog,
    "filter-panel": FilterPanel,
  },
  data() {
    return {
      tab: -1,
      tmpStocks: [],
      productIdToStocks: {},
      selectedProduct: {},
      products: [],
      store: {},
      isLoadingIdx: -1,
      isLoading: false,
      inStockDialog: false,
    };
  },
  computed: {
    cart() {
      return this.$store.getters["cart"];
    },
    cartCount() {
      return this.$store.getters["cartCount"];
    },
  },
  methods: {
    addStock(product) {
      console.log("check");
      this.selectedProduct = product;
      this.inStockDialog = true;
    },
    applyFilter(filter) {
      this.getProductsByStoreId(this.store.id, filter);
    },
    getProductsByStoreId(storeId, filter) {
      this.isLoading = true;
      backendService.getProductsByStoreId(storeId, filter).then((response) => {
        this.productIdToStocks = {};
        this.timer(1000).then(() => {
          this.isLoading = false;
          this.products = response.data;
          this.products.forEach((product) => {
            this.productIdToStocks[product.id] = [];
          });
        });
      });
    },
    getStocksByProductId(productId) {
      backendService.getStocksByProductId(productId).then((response) => {
        this.productIdToStocks[productId] = response.data;
        this.$forceUpdate();
      });
    },
    getStoreById(storeId) {
      backendService.getStoreById(storeId).then((response) => {
        this.store = response.data;
      });
    },
  },
};
</script>
