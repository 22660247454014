<template>
  <v-card elevation="0" min-width="300px">
    <v-container><h3>Categories</h3></v-container>
    <v-list shaped>
      <v-list-item-group v-model="selectedItem">
        <v-list-item
          v-for="(category, i) in categories"
          :key="i"
          @click="
            $router
              .push({
                name: 'CategoryPage',
                params: { id: category.id },
              })
              .catch({})
          ">
          <v-list-item-icon
            ><i :class="category.icon" style="color: gray; font-size: 20px"></i>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title
              ><span class="primary--text">{{
                category.displayName
              }}</span></v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-card>
</template>
<script>
export default {
  name: "CategoryList",
  data() {
    return { selectedItem: null };
  },
  computed: {
    categories() {
      if (
        this.$store.getters["dropdownToOptions"] &&
        this.$store.getters["dropdownToOptions"]["category"]
      ) {
        return this.$store.getters["dropdownToOptions"]["category"].options;
      }
      return [];
    },
  },
  methods: {},
};
</script>
<style scoped>
/* .v-list .v-list-item:hover {
  background-color: #ff9100 !important;
  color: white;
} */
</style>
