import Vue from "vue";
import VueRouter from "vue-router";
//import Vuex from 'vuex'
// import Home from '../views/Home.vue'
import CategoryPage from "../views/CategoryPage.vue";
import ProductDetail from "../views/ProductDetail.vue";

// import ProductThumbnail from '../views/ProductThumbnail.vue';
import ShoppingCart from "../views/ShoppingCart.vue";
import OrderList from '../views/OrderList.vue';
import OrderDetail from '../views/OrderDetail.vue';
// import OrderDeliveryInfo from '../views/OrderDeliveryInfo.vue';
import HomePage from "@/views/HomePage.vue";
import EmailVerify from "@/views/VerifyAccount.vue";
import LoginPage from "@/views/LoginPage.vue";
import LogoutPage from "@/views/LogoutPage.vue";
// import CustomerOrderList from '../views/CustomerOrderList.vue';
// import ContactAdmin from '../views/ContactAdmin.vue';
// import VerifyAccount from '../views/VerifyAccount.vue';
// import Ads from '../views/Ads.vue';
import SearchPage from "@/views/SearchPage.vue"
import ProductForm from "@/views/ProductForm.vue";
//import ProductList from "../views/ProductList.vue";
import StoreRegister from "@/views/StoreRegister.vue";
import StorePage from "@/views/StorePage.vue";
import UserRegister from "@/views/UserRegister.vue";
import StoreList from "@/views/StoreList.vue";
import StoreManagement from "@/views/StoreManagement.vue";
import TypePage from "@/views/TypePage.vue";
Vue.use(VueRouter);
const routes = [
	{
		path: "/cart",
		name: "ShoppingCart",
		component: ShoppingCart,
	},
	{
		path: "/",
		name: "HomePage",
		component: HomePage,
	},
	{
		path: "/login",
		name: "Login",
		component: LoginPage,
	},
	{
		path: "/logout",
		name: "Logout",
		component: LogoutPage,
	},
	{
		path: "/register/store",
		name: "StoreRegister",
		component: StoreRegister,
	},
	{
		path: "/register/user",
		name: "UserRegister",
		component: UserRegister,
	},
	{
		path: "/verify/email/:sessionId",
		name: "EmailVerify",
		component: EmailVerify,
	},
	
	{
		path: "/add/product/:storeId",
		name: "ProductForm",
		component: ProductForm,
	},
	{
		path: "/product/detail/:id",
		name: "ProductDetail",
		component: ProductDetail,
	},
	{
		path: "/update/product/:id",
		name: "ProductUpdate",
		component: ProductForm,
	},
	// {
	// 	path: "/product/list",
	// 	name: "ProductList",
	// 	component: ProductList,
	// },
	{
		path:"/orders",
		name:"OrderList",
		component:OrderList
	},
	{
		path:"/view/category/:id",
		name:"CategoryPage",
		component:CategoryPage,
	},
	{
		path:"/view/type/:id",
		name:"TypePage",
		component:TypePage,
	},
	{
		path:"/store/list",
		name:"StoreList",
		component:StoreList,
	},
	{
		path:"/view/store/:id",
		name:"StorePage",
		component:StorePage,
	},
	{
		path:"/store/management/:id",
		name:"StoreManagement",
		component:StoreManagement
	},
	{
		path:"/order/:id",
		name:"OrderDetail",
		component:OrderDetail,
	},
	{
		path:"/search/:searchText",
		name:"SearchPage",
		component:SearchPage
	},
	
	
	
	//   {
	//     path: "/Inventory",
	//     name: "Inventory",
	//     component: Inventory,
	//     beforeEnter: (to, from, next) => {
	//       if (localStorage.getItem("currentAccount")) {
	//         let currentAccount = JSON.parse(localStorage.getItem("currentAccount"));
	//         backendService
	//           .verifyToken(currentAccount.token)
	//           .then((response) => {
	//             store.commit("setIsLoggedIn", true);
	//             store.commit("setCurrentUser", currentAccount);
	//             let role = response.data;
	//             if (role == "admin") {
	//               next();
	//             } else {
	//               next({ name: "ContactAdmin" });
	//             }
	//           })
	//           .catch((err) => {
	//             localStorage.setItem("redirect", window.location);
	//             next({ name: "Login" });
	//           });
	//       } else {
	//         localStorage.setItem("redirect", window.location);
	//         next({ name: "Login" });
	//       }
	//     },
	//   },
	//   {
	//     path: "/Inventory/:id",
	//     name: "Inventory",
	//     component: Inventory,
	//     beforeEnter: (to, from, next) => {
	//       if (localStorage.getItem("currentAccount")) {
	//         let currentAccount = JSON.parse(localStorage.getItem("currentAccount"));
	//         backendService
	//           .verifyToken(currentAccount.token)
	//           .then((response) => {
	//             store.commit("setIsLoggedIn", true);
	//             store.commit("setCurrentUser", currentAccount);
	//             let role = response.data;
	//             if (role == "admin") {
	//               next();
	//             } else {
	//               next({ name: "ContactAdmin" });
	//             }
	//           })
	//           .catch((err) => {
	//             localStorage.setItem("redirect", window.location);
	//             next({ name: "Login" });
	//           });
	//       } else {
	//         localStorage.setItem("redirect", window.location);
	//         next({ name: "Login" });
	//       }
	//     },
	//   },

	//   {
	//     path: "/register/user",
	//     name: "UserRegister",
	//     component: UserRegister,
	//   },
	//   {
	//     path: "/Login",
	//     name: "Login",
	//     component: Login,
	//   },
	//   {
	//     path: "/About",
	//     name: "About",
	//     // route level code-splitting
	//     // this generates a separate chunk (about.[hash].js) for this route
	//     // which is lazy-loaded when the route is visited.
	//     component: function () {
	//       return import(/* webpackChunkName: "about" */ "../views/About.vue");
	//     },
	//   },

	//   {
	//     path: "/ProductList",
	//     name: "ProductList",
	//     component: ProductList,
	//     beforeEnter: (to, from, next) => {
	//       if (localStorage.getItem("currentAccount")) {
	//         let currentAccount = JSON.parse(localStorage.getItem("currentAccount"));
	//         backendService
	//           .verifyToken(currentAccount.token)
	//           .then((response) => {
	//             store.commit("setIsLoggedIn", true);
	//             store.commit("setCurrentUser", currentAccount);
	//             let role = response.data;
	//             if (role == "admin") {
	//               next();
	//             } else {
	//               next({ name: "ContactAdmin" });
	//             }
	//           })
	//           .catch((err) => {
	//             localStorage.setItem("redirect", window.location);
	//             next({ name: "Login" });
	//           });
	//       } else {
	//         localStorage.setItem("redirect", window.location);
	//         next({ name: "Login" });
	//       }
	//     },
	//   },
	//   {
	//     path: "/ProductThumbnail/p/:product",
	//     name: "ProductThumbnail",
	//     component: ProductThumbnail,
	//     props: true,
	//   },
	//   {
	//     path: "/OrderList",
	//     name: "OrderList",
	//     component: OrderList,
	//     beforeEnter: (to, from, next) => {
	//       if (localStorage.getItem("currentAccount")) {
	//         let currentAccount = JSON.parse(localStorage.getItem("currentAccount"));
	//         backendService
	//           .verifyToken(currentAccount.token)
	//           .then((response) => {
	//             store.commit("setIsLoggedIn", true);
	//             store.commit("setCurrentUser", currentAccount);
	//             let role = response.data;
	//             if (role == "admin" || role == "deliveryPerson") {
	//               next();
	//             } else {
	//               next({ name: "ContactAdmin" });
	//             }
	//           })
	//           .catch((err) => {
	//             console.log(err);
	//             localStorage.setItem("redirect", window.location);
	//             next({ name: "Login" });
	//           });
	//       } else {
	//         console.log("no access token");
	//         localStorage.setItem("redirect", window.location);
	//         next({ name: "Login" });
	//       }
	//     },
	//   },
	//   {
	//     path: "/OrderDeliveryInfo/:id",
	//     name: "OrderDeliveryInfo",
	//     component: OrderDeliveryInfo,
	//     beforeEnter: (to, from, next) => {
	//       if (localStorage.getItem("currentAccount")) {
	//         let currentAccount = JSON.parse(localStorage.getItem("currentAccount"));
	//         backendService
	//           .verifyToken(currentAccount.token)
	//           .then((response) => {
	//             store.commit("setIsLoggedIn", true);
	//             store.commit("setCurrentUser", currentAccount);
	//             let role = response.data;
	//             if (role == "admin" || role == "deliveryPerson") {
	//               next();
	//             } else {
	//               next({ name: "ContactAdmin" });
	//             }
	//           })
	//           .catch((err) => {
	//             localStorage.setItem("redirect", window.location);
	//             next({ name: "Login" });
	//           });
	//       } else {
	//         localStorage.setItem("redirect", window.location);
	//         next({ name: "Login" });
	//       }
	//     },
	//   },
	//   {
	//     path: "/CustomerOrderList",
	//     name: "CustomerOrderList",
	//     component: CustomerOrderList,
	//     beforeEnter: (to, from, next) => {
	//       if (localStorage.getItem("currentAccount")) {
	//         let currentAccount = JSON.parse(localStorage.getItem("currentAccount"));
	//         backendService
	//           .verifyToken(currentAccount.token)
	//           .then((response) => {
	//             store.commit("setIsLoggedIn", true);
	//             store.commit("setCurrentUser", currentAccount);
	//             next();
	//           })
	//           .catch((err) => {
	//             localStorage.setItem("redirect", window.location);
	//             next({ name: "Login" });
	//           });
	//       } else {
	//         localStorage.setItem("redirect", window.location);
	//         next({ name: "Login" });
	//       }
	//     },
	//   },
	//   {
	//     path: "/ContactAdmin",
	//     name: "ContactAdmin",
	//     component: ContactAdmin,
	//   },
	//   {
	//     path: "/Verify/:id",
	//     name: "VerifyAccount",
	//     component: VerifyAccount,
	//   },
	//   {
	//     path: "/Ads/:id",
	//     name: "Ads",
	//     component: Ads,
	//   },
];

const router = new VueRouter({
	routes,
});
export default router;
