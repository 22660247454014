<template>
  <v-carousel height="400px">
    <v-carousel-item v-for="slide in slides" :key="slide.url">
      <v-img :src="slide" style="width: 100%"></v-img>
    </v-carousel-item>
  </v-carousel>
</template>
<script>
export default {
  name: "CarouselSlide",
  data() {
    return {
      slides: [
        require("@/assets/banner-2.png"),
        require("@/assets/banner-3.png"),
        require("@/assets/banner-4.png"),
      ],
    };
  },
};
</script>
<style scoped></style>
