<template>
  <v-card class="rounded-xl" elevation="0" width="20%" height="600px">
    <v-card-title>
      <h4>Filter</h4>
    </v-card-title>
    <v-card-text>
      <v-form>
        <div class="d-flex">
          <v-text-field
            class="mr-2"
            label="Min Price"
            v-model.number="filter.minPrice"
            @change="
              filter.minPrice = filter.minPrice != '' ? filter.minPrice : null
            "
            prefix="$"></v-text-field>
          <v-text-field
            class="ml-2"
            label="Max Price"
            v-model.number="filter.maxPrice"
            @change="
              filter.maxPrice = filter.maxPrice != '' ? filter.maxPrice : null
            "
            prefix="$"></v-text-field>
        </div>
        <div class="d-flex">
          <v-text-field
            class="mr-2"
            label="Min Qauntity"
            @change="
              filter.minQuantity =
                filter.minQuantity != '' ? filter.minQuantity : null
            "
            v-model.number="filter.minQuantity"></v-text-field>
          <v-text-field
            class="ml-2"
            label="Max Qauntity"
            @change="
              filter.maxQuantity =
                filter.maxQuantity != '' ? filter.maxQuantity : null
            "
            v-model.number="filter.maxQuantity"></v-text-field>
        </div>

        <v-autocomplete
          label="Brand"
          v-model.number="filter.brandId"></v-autocomplete>
        <v-autocomplete
          label="Category"
          v-model.number="filter.lvl1"></v-autocomplete>
        <v-autocomplete
          label="Type"
          v-model.number="filter.lvl2"></v-autocomplete>
        <v-autocomplete
          label="Status"
          v-model="filter.isActive"></v-autocomplete>
        <div fluid class="d-flex justify-end">
          <v-btn
            color="primary"
            depressed
            rounded
            @click="emitEvent"
            :loading="isLoading">
            <i class="fas fa-sync-alt mr-2"></i>
            Apply
          </v-btn>
        </div>
      </v-form>
    </v-card-text>
  </v-card>
</template>
<script>
import generalMixin from "@/js/general-mixin";
export default {
  name: "FilterPanel",
  mixins: [generalMixin],
  data() {
    return { isLoading: false, filter: {} };
  },
  methods: {
    emitEvent() {
      this.isLoading = true;
      this.$emit("handle-event", this.filter);
      this.timer(1000).then(() => {
        this.isLoading = false;
      });
    },
  },
};
</script>
