import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import store from "./stores";
import router from "./router";
import CKEditor from "@ckeditor/ckeditor5-vue2";
import VuetifyMoney from "./plugins/vuetify-money.js";
import VueMarqueeSlider from 'vue-marquee-slider';
Vue.use(CKEditor);
Vue.use(VueMarqueeSlider)
Vue.config.productionTip = false;

new Vue({
     vuetify,
     router,
     store,
     VuetifyMoney,
     render: (h) => h(App),
}).$mount("#app");
