<template>
  <v-container fluid class="white">
    <v-container
      ><h3>{{ title }}</h3></v-container
    >
    <v-container
      class="d-flex"
      :class="{ 'justify-space-between': sublst.length == num }"
      fluid
      v-for="(sublst, idxI) in formattedProducts"
      :key="`sublst-${idxI}`">
      <product-card
        :class="{ 'mr-2': sublst.length < num }"
        v-for="product in sublst"
        :key="`product-${product.id}`"
        :product="product"
        :allow-add-to-cart="allowAddToCart"
        :title-length="titleLength"
        :wide="wide"></product-card>
    </v-container>
    <v-container
      v-if="formattedProducts.length == 0"
      class="text-center grey--text">
      <h2>
        <span class="mr-2">Nothing to see here</span>
        <i class="far fa-frown"></i>
      </h2>
    </v-container>
  </v-container>
</template>
<script>
import ProductCard from "./ProductCard.vue";
export default {
  name: "ProductThumbnailList",
  props: {
    allowAddToCart: {
      type: Boolean,
      default: false,
    },
    products: {
      type: Array,
      default: new Array(),
    },
    title: {
      type: String,
      default: "",
    },
    titleLength: {
      type: Number,
      default: 0,
    },
    wide: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    formattedProducts() {
      let formattedLst = [];
      if (this.products.length == 0) return formattedLst;
      for (let i = 0; i <= this.products.length / this.num; i++) {
        let sublst = [];
        for (let j = 0; j < this.num; j++) {
          let product = this.products[i * this.num + j];
          if (product) {
            sublst.push(product);
          }
        }
        formattedLst.push(sublst);
      }
      return formattedLst;
    },
  },
  data() {
    return {
      num: 7,
    };
  },
  components: { "product-card": ProductCard },
};
</script>
