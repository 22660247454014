<template>
  <v-dialog v-model="dialog" max-width="350">
    <v-card>
      <v-card-title class="text-h5">
        ពត៍មានដឹកជញ្ជូន - Delivery Info
      </v-card-title>
      <v-card-text>
        <v-text-field
          label="ផ្ទះនិងផ្លូវ - Address Line 1"
          v-model="deliveryInfo.address1"></v-text-field>
        <v-text-field
          label="ខណ្ឌនិងសង្កាត់ - Address Line 2"
          v-model="deliveryInfo.address2"></v-text-field>
        <v-text-field
          label="លេខទូរស័ព្ទ - Phone"
          v-model="deliveryInfo.phone"></v-text-field>
        <v-checkbox
          label="ទីតាំងមេ - New Default"
          v-model="deliveryInfo.main"></v-checkbox>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn color="primary" depressed rounded @click="addDeliveryAddress">
          <i class="fas fa-plus"></i>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import backendService from "@/services/backend-service.js";
export default {
  name: "DeliveryDialog",
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      deliveryInfo: {
        address1: "",
        address2: "",
        phone: "",
        main: true,
      },
    };
  },
  computed: {
    dialog: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  created: function () {},

  methods: {
    addDeliveryAddress() {
      backendService.addDeliveryAddress(this.deliveryInfo).then((response) => {
        this.$emit("handle-event", response.insertedId);
        this.dialog = false;
        this.deliveryInfo = {
          address1: "",
          address2: "",
          phone: "",
        };
      });
    },
  },
};
</script>
<style scoped></style>
