<template>
  <v-container fluid class="white rounded-xl">
    <h1>Your Orders</h1>
    <v-container
      v-if="orders.length < 1"
      class="grey lighten-2 rounded-xl text-center d-flex flex-column"
      ><h2>Nothing to See</h2>
      <i class="fas fa-spider"></i
    ></v-container>
    <v-card
      v-for="order in orders"
      :key="`order-${order.id}`"
      class="mb-2 rounded-xl"
      elevation="0"
      outlined>
      <v-card-text class="d-flex justify-space-between blue-grey lighten-5">
        <div>
          <span class="font-weight-bold">Order #</span>
          <span>
            <br />
            <h2>
              <a
                @click="
                  $router.push({
                    name: 'OrderDetail',
                    params: { id: order.id },
                  })
                ">
                {{ order.id }}
              </a>
            </h2>
          </span>
        </div>
        <div>
          <span class="font-weight-bold">Ship to</span>
          <span>
            <br />
            {{ order.deliveryAddress.address1 }}
          </span>
          <span>
            <br />
            {{ order.deliveryAddress.address2 }}
          </span>
        </div>
        <div>
          <span class="font-weight-bold">Order Placed</span>
          <span>
            <br />
            {{ convertToDate(order.dateCreated) }}
          </span>
        </div>
        <div>
          <span class="font-weight-bold">Total</span>
          <span>
            <br />
            $
            {{ order.subtotal + order.deliveryFee }}
          </span>
        </div>
      </v-card-text>
      <v-card-text>
        <v-container
          v-for="orderDetail in order.orderDetails"
          :key="`order-detail-${orderDetail.id}`"
          class="d-flex">
          <img
            class="image-preview"
            :src="getProductPictureUrlById(orderDetail.pictureId)" />
          <v-container class="d-flex flex-column">
            <a
              @click="
                $router.push({
                  name: 'ProductDetail',
                  params: { id: orderDetail.productId },
                })
              ">
              {{ orderDetail.productTitle }}
            </a>
            <span class="red--text"> $ {{ orderDetail.unitPrice }} </span>
            <span>Qty: {{ orderDetail.quantity }}</span>
          </v-container>
          <v-container class="d-flex justify-space-between">
            <!-- <h2>
              Status: <span>{{ getOptionName(orderDetail.statusId) }}</span>
            </h2> -->
            <v-autocomplete
              class="mr-2"
              label="Status"
              :items="itemStatuses"
              item-text="displayName"
              item-value="id"
              v-model="orderDetail.statusId"
              rounded
              dense
              outlined></v-autocomplete
            ><v-btn
              color="warning"
              rounded
              depressed
              @click="updateItemStatus(orderDetail.id, orderDetail.statusId)"
              :loading="orderDetail.id == selectedOrderDetail"
              ><i class="fas fa-chevron-right mr-2"></i>Update Status</v-btn
            >
          </v-container>
        </v-container>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
//import eventBus from "@/js/event-bus";
import backendService from "@/services/backend-service.js";
import generalMixin from "@/js/general-mixin";
export default {
  name: "StoreOrderList",
  mixins: [generalMixin],
  props: {
    storeId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      orders: [],
      selectedOrderDetail: -1,
    };
  },
  created: function () {
    this.getOrders();
  },
  computed: {
    itemStatuses() {
      return this.$store.getters["dropdownToOptions"]["orderStatus"].options;
    },
  },
  methods: {
    getProductPictureUrlById(imageId) {
      return backendService.getProductPictureUrlById(imageId);
    },
    getOrders() {
      backendService.getOrdersByStoreId(this.storeId).then((response) => {
        this.orders = response.data;
      });
    },
    updateItemStatus(orderDetailId, statusId) {
      let itemStatus = {
        orderDetailId: orderDetailId,
        statusId: statusId,
      };
      this.selectedOrderDetail = orderDetailId;
      backendService.updateItemStatus(itemStatus).then(() => {
        this.timer(1000).then(() => {
          this.selectedOrderDetail = -1;
        });
      });
    },
  },
};
</script>
<style scoped>
.image-preview {
  object-fit: contain;
  image-rendering: smooth;
  width: 100px;
  height: 100px;
}
</style>
