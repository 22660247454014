var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"white rounded-xl",attrs:{"fluid":""}},[_c('h1',[_vm._v("Your Orders")]),(_vm.orders.length < 1)?_c('v-container',{staticClass:"grey lighten-2 rounded-xl text-center d-flex flex-column"},[_c('h2',[_vm._v("Nothing to See")]),_c('i',{staticClass:"fas fa-spider"})]):_vm._e(),_vm._l((_vm.orders),function(order){return _c('v-card',{key:`order-${order.id}`,staticClass:"mb-2 rounded-xl",attrs:{"elevation":"0","outlined":""}},[_c('v-card-text',{staticClass:"d-flex justify-space-between blue-grey lighten-5"},[_c('div',[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Order #")]),_c('span',[_c('br'),_c('h2',[_c('a',{on:{"click":function($event){return _vm.$router.push({
                  name: 'OrderDetail',
                  params: { id: order.id },
                })}}},[_vm._v(" "+_vm._s(order.id)+" ")])])])]),_c('div',[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Ship to")]),_c('span',[_c('br'),_vm._v(" "+_vm._s(order.deliveryAddress.address1)+" ")]),_c('span',[_c('br'),_vm._v(" "+_vm._s(order.deliveryAddress.address2)+" ")])]),_c('div',[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Order Placed")]),_c('span',[_c('br'),_vm._v(" "+_vm._s(_vm.convertToDate(order.dateCreated))+" ")])]),_c('div',[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Total")]),_c('span',[_c('br'),_vm._v(" $ "+_vm._s(order.subtotal + order.deliveryFee)+" ")])])]),_c('v-card-text',_vm._l((order.orderDetails),function(orderDetail){return _c('v-container',{key:`order-detail-${orderDetail.id}`,staticClass:"d-flex"},[_c('img',{staticClass:"image-preview",attrs:{"src":_vm.getProductPictureUrlById(orderDetail.pictureId)}}),_c('v-container',{staticClass:"d-flex flex-column"},[_c('a',{on:{"click":function($event){return _vm.$router.push({
                name: 'ProductDetail',
                params: { id: orderDetail.productId },
              })}}},[_vm._v(" "+_vm._s(orderDetail.productTitle)+" ")]),_c('span',{staticClass:"red--text"},[_vm._v(" $ "+_vm._s(orderDetail.unitPrice)+" ")]),_c('span',[_vm._v("Qty: "+_vm._s(orderDetail.quantity))])]),_c('v-container',{staticClass:"d-flex justify-space-between"},[_c('v-autocomplete',{staticClass:"mr-2",attrs:{"label":"Status","items":_vm.itemStatuses,"item-text":"displayName","item-value":"id","rounded":"","dense":"","outlined":""},model:{value:(orderDetail.statusId),callback:function ($$v) {_vm.$set(orderDetail, "statusId", $$v)},expression:"orderDetail.statusId"}}),_c('v-btn',{attrs:{"color":"warning","rounded":"","depressed":"","loading":orderDetail.id == _vm.selectedOrderDetail},on:{"click":function($event){return _vm.updateItemStatus(orderDetail.id, orderDetail.statusId)}}},[_c('i',{staticClass:"fas fa-chevron-right mr-2"}),_vm._v("Update Status")])],1)],1)}),1)],1)})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }