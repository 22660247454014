<template>
  <v-container fluid class="w-75">
    <v-container class="white rounded-xl mb-2">
      <div class="d-flex justify-space-between">
        <div class="mr-5"><category-list></category-list></div>
        <div class="pa-2">
          <carousel-slide class="mb-2"></carousel-slide>
        </div>
      </div>
      <!-- <div class="d-flex justify-space-between">
        <v-card class="mx-2 pb-2" max-width="300" elevation="0">
          <v-img
            src="https://aeoncambodia.sgp1.digitaloceanspaces.com/image/promotion_banners/20240119/1705666095-Super-Deal.png"
            height="400px"></v-img>
        </v-card>
        <v-card class="mx-2 pb-2" max-width="300" elevation="0">
          <v-img
            src="https://aeoncambodia.sgp1.digitaloceanspaces.com/image/promotion_banners/20240130/1706613962-Super-Deal.png"
            height="400px"></v-img>
        </v-card>
        <v-card class="mx-2 pb-2" max-width="300" elevation="0">
          <v-img
            src="https://aeoncambodia.sgp1.digitaloceanspaces.com/image/promotion_banners/20240130/1706615298-Super-Deal.png"
            height="400px"></v-img>
        </v-card>
        <v-card class="mx-2 pb-2" max-width="300" elevation="0">
          <v-img
            src="https://aeoncambodia.sgp1.digitaloceanspaces.com/image/promotion_banners/20240208/1707378759-New-Project-(6).webp"
            height="400px"></v-img>
        </v-card>
      </div> -->
    </v-container>

    <v-container class="rounded-xl white mb-2" v-if="this.products.length > 0"
      ><v-container><h3>Our Products</h3></v-container>
      <vue-marquee-slider
        id="marquee-slider"
        :speed="speed"
        :width="250"
        :space="20">
        <product-card
          v-for="product in products"
          :key="`product-${product.id}`"
          :product="product"
          allow-add-to-cart></product-card>
      </vue-marquee-slider>
      <vue-marquee-slider
        id="marquee-slider-2"
        :speed="speed"
        :width="250"
        :space="20"
        reverse>
        <product-card
          v-for="product in products"
          :key="`product-${product.id}`"
          :product="product"
          allow-add-to-cart></product-card>
      </vue-marquee-slider>
    </v-container>
    <v-container class="rounded-xl white mb-2">
      <product-card-list
        title="Popular Viewed Items"
        allow-add-to-cart
        :products="products"
        :title-length="64"></product-card-list>
    </v-container>
  </v-container>
</template>

<script>
import CategoryList from "@/components/CategoryList.vue";
import CarouselSlide from "@/components/CarouselSlide.vue";
import ProductCardList from "@/components/ProductCardList.vue";
import ProductCard from "@/components/ProductCard.vue";
import backendService from "@/services/backend-service";
export default {
  name: "HomePage",
  components: {
    "carousel-slide": CarouselSlide,
    "category-list": CategoryList,
    "product-card-list": ProductCardList,
    "product-card": ProductCard,
  },
  mounted: function () {
    this.getProducts();
  },
  data() {
    return {
      products: [],
    };
  },
  computed: {
    speed() {
      return (20000 * this.products.length) / 4;
    },
  },
  methods: {
    getProducts() {
      backendService.getProducts().then((response) => {
        console.log(response.data);
        this.products = response.data;
      });
    },
  },
};
</script>
