<template>
  <v-card
    class="mb-2 card-border rounded-xl"
    outlined
    :width="wide ? '100%' : '250px'">
    <v-card-text class="pt-0 px-0">
      <div :class="{ 'ml-2': wide }">
        <div class="d-flex" :class="{ 'justify-center': !wide }">
          <img
            @click="
              $router.push({
                name: 'ProductDetail',
                params: { id: product.id },
              })
            "
            class="image-preview"
            :src="getProductPictureUrlById(product.pictureId)" />
          <v-container v-if="wide">
            <a
              class="kh align-center"
              @click="
                $router.push({
                  name: 'ProductDetail',
                  params: { id: product.id },
                })
              ">
              {{ productTitle }}
            </a>
            <div>
              <div class="red--text">
                <h3>$ {{ product.unitPrice }}</h3>
              </div>
              <span v-if="showQty">Qty: {{ product.quantity }}</span>
              <div v-else><i class="fas fa-flag-usa mr-2"></i>Ship from US</div>
            </div>
          </v-container>
        </div>
      </div>
      <div
        v-if="!wide"
        class="text-center"
        style="
          width: 100%;
          height: 2rem;
          font-size: 12px;
          line-height: 15px;
          overflow: hidden;
        ">
        <a
          class="kh"
          @click="
            $router.push({
              name: 'ProductDetail',
              params: { id: product.id },
            })
          ">
          {{ productTitle }}
        </a>
      </div>
    </v-card-text>
    <v-card-text class="py-0 kh" v-if="!wide">
      <div class="d-flex flex-wrap justify-space-between">
        <span v-if="showQty">Qty: {{ product.quantity }}</span>
        <span v-else><i class="fas fa-flag-usa mr-2"></i>Ship from US</span>
        <span class="red--text"> $ {{ product.unitPrice }} </span>
      </div>
    </v-card-text>
    <v-card-actions class="d-flex justify-center" v-if="!wide">
      <v-btn
        v-if="allowAddToCart"
        block
        rounded
        depressed
        color="primary"
        :loading="isLoading"
        @click="addToCart(product)">
        <i class="fas fa-cart-plus mr-2"></i>
        Add to Cart
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import backendService from "@/services/backend-service";
import eventBus from "@/js/event-bus";
import generalMixin from "@/js/general-mixin";
export default {
  name: "ProductCard",
  mixins: [generalMixin],
  props: {
    showQty: {
      type: Boolean,
      default: false,
    },
    allowAddToCart: {
      type: Boolean,
      default: false,
    },
    wide: {
      type: Boolean,
      default: false,
    },
    product: {
      type: Object,
      default: () => {
        return {};
      },
    },
    titleLength: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    cart() {
      return this.$store.getters["cart"];
    },
    cartCount() {
      return this.$store.getters["cartCount"];
    },
    productTitle() {
      if (this.titleLength > 0)
        return this.product.title.substring(0, this.titleLength);
      return this.product.title;
    },
  },
  methods: {
    getProductPictureUrlById(imageId) {
      return backendService.getProductPictureUrlById(imageId);
    },
    addToCart() {
      let cart = this.cart;
      let cartCount = this.cartCount;
      if (!cart[this.product.id]) {
        let item = { product: this.product, count: 0 };
        this.$set(cart, this.product.id, item);
      }
      cart[this.product.id].count += 1;
      this.$store.commit("setCart", cart);
      this.$store.commit("setCartCount", cartCount + 1);

      localStorage.setItem("cartCount", cartCount + 1);
      localStorage.setItem("cart", JSON.stringify(cart));

      this.isLoading = true;
      this.timer(1000).then(() => {
        this.isLoading = false;
        eventBus.$emit(
          "setSnackbar",
          "Item added to the cart",
          "success",
          true
        );
      });
    },
  },
};
</script>
<style scoped>
.image-preview {
  object-fit: contain;
  image-rendering: smooth;
  width: 150px;
  height: 150px;
}
.card-border {
  border: 1px solid lightgrey;
}
.card-border:hover {
  border: 2px solid orange;

  cursor: pointer;
}
</style>
