<template>
  <v-container fluid class="d-flex justify-space-between">
    <v-card class="rounded-xl" elevation="0" width="78%">
      <v-card-title class="d-flex align-center justify-space-between">
        <h3>Shopping Cart</h3>
        <div class="mr-6">Price</div>
      </v-card-title>
      <v-card-text class="d-flex flex-column" v-if="!isSuccessful">
        <v-divider></v-divider>
        <v-card v-for="(item, key) in cart" :key="key" elevation="0">
          <v-card-text class="d-flex justify-start">
            <div class="d-flex mr-5"></div>
            <div>
              <img
                class="image-preview"
                :src="getProductPictureUrlById(item.product.pictureId)" />
            </div>
            <v-container class="d-flex flex-column">
              <div class="d-flex justify-space-between mb-2">
                <h3 style="width: 80%" class="kh">
                  {{ item.product.title }}
                </h3>
                <h3 class="red--text">
                  $
                  {{ item.product.unitPrice }}
                </h3>
              </div>
              <div style="width: 30%" class="d-flex">
                <v-text-field
                  dense
                  rounded
                  outlined
                  label="Quantity"
                  v-model="item.count"
                  @change="updateItemCount(key)"></v-text-field>
                <v-btn
                  class="ml-4"
                  @click="
                    toRemove = key;
                    removeDialog = true;
                  "
                  rounded
                  depressed
                  color="error">
                  <i class="fas fa-times"></i>
                </v-btn>
              </div>
            </v-container>
          </v-card-text>
          <v-divider></v-divider>
        </v-card>
        <div class="d-flex justify-end">
          <h2 class="red--text">$ {{ subtotal }}</h2>
        </div>
      </v-card-text>
      <v-card-text v-if="isSuccessful">
        <v-card elevation="0" outlined>
          <v-card-text class="d-flex justify-center">
            <div class="text-center">
              <h3 class="mb-5 green--text">
                អរគុណ! ពួកយេីងបានទទួលការកម្ម៉ង់របស់អ្នកហេីយ។
                <br />
                Thank you, your order has been placed.
              </h3>
              <h3 class="mb-5 green--text"></h3>
              <div>
                ពួកយេីងបានផ្ញេីរសារទៅអ្នកដែលភ្ជាប់ជាមួយលេខកម៉្មង់របស់អ្នក។
                លេខកម៉្មង់របស់អ្នកគឺ - {{ orderId }}
              </div>
              <img src="@/assets/29-happy.png" />
            </div>
          </v-card-text>
        </v-card>
      </v-card-text>
    </v-card>

    <v-card elevation="0" class="rounded-xl" outlined width="20%">
      <v-card-text>
        <v-card elevation="0">
          <v-card-text>
            <div class="d-flex justify-space-between">
              <h4>Subtotal</h4>
              <h4 class="red--text">$ {{ subtotal.toFixed(2) }}</h4>
            </div>
            <div class="d-flex justify-space-between">
              <h4>Delivery Fee</h4>
              <h4 class="red--text">$ {{ deliveryFee.toFixed(2) }}</h4>
            </div>
            <div class="d-flex justify-space-between">
              <h3>Total</h3>
              <h3 class="red--text">
                $ {{ subtotal.plus(deliveryFee).toFixed(2) }}
              </h3>
            </div>
          </v-card-text>
        </v-card>
        <v-card class="mb-2" elevation="0">
          <v-card-text class="px-0">
            <v-list shaped dense>
              <v-list-item-group color="primary">
                <v-list-item>
                  <v-radio-group
                    v-model="order.deliveryAddressId"
                    style="width: 100%">
                    <div
                      class="d-flex justify-space-between"
                      v-for="(deliveryAddress, idx) in deliveryAddresses"
                      :key="deliveryAddress.id">
                      <v-radio :value="deliveryAddress.id">
                        <template v-slot:label>
                          <div style="font-size: 12px">
                            {{ deliveryAddress.address1 }}
                            <br v-if="deliveryAddress.address2.length > 0" />
                            {{ deliveryAddress.address2 }}
                            <br />
                            <span class="font-weight-bold">
                              {{ deliveryAddress.phone }}
                            </span>
                          </div>
                        </template>
                      </v-radio>
                      <div class="d-flex align-center">
                        <v-btn
                          icon
                          color="red"
                          @click="
                            toDelete = idx;
                            deleteDialog = true;
                          ">
                          <i class="fas fa-times"></i>
                        </v-btn>
                      </div>
                    </div> </v-radio-group
                ></v-list-item>
              </v-list-item-group>
            </v-list>

            <v-btn
              rounded
              text
              color="primary"
              class="mb-5"
              @click="deliveryInfoDialog = !deliveryInfoDialog">
              <i class="fas fa-plus mr-2"></i>
              Add Address
            </v-btn>
            <v-autocomplete
              v-model="paymentType"
              item-text="displayName"
              label="Payment Preference"
              :items="paymentTypes"
              return-object></v-autocomplete>
            <div v-if="paymentType != null && paymentType.strRef == 'abaPay'">
              <img
                src="@/assets/aba-pay.png"
                style="
                  width: 100%;
                  object-fit: contain;
                  image-rendering: smooth;
                " />
              <v-text-field
                label="លេខប្រតិបត្តិការណ៍ - Reference Id"
                v-model="payment.referenceNumber"></v-text-field>
            </div>
          </v-card-text>
        </v-card>
        <v-card></v-card>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          v-show="cashPayment"
          color="warning"
          depressed
          rounded
          @click="openCashDialog"
          :loading="isLoading"
          :disabled="validSubmit">
          <i class="fas fa-paper-plane mr-2"></i>
          Submit Order
        </v-btn>
        <v-btn
          v-show="!cashPayment"
          color="warning"
          depressed
          rounded
          @click="submitOrder"
          :loading="isLoading"
          :disabled="validSubmit">
          <i class="fas fa-paper-plane mr-2"></i>
          Submit Order
        </v-btn>
      </v-card-actions>
    </v-card>
    <cash-dialog
      v-model="cashDialog"
      :p-total-amount="totalAmount"
      @total-amount="
        (val) => {
          totalAmount = val;
        }
      "
      @handle-event="submitOrder"></cash-dialog>
    <delivery-info-dialog
      v-model="deliveryInfoDialog"
      @handle-event="setDeliveryInfoId"></delivery-info-dialog>
    <general-dialog
      :dialog="deleteDialog"
      :options="deleteOptions"
      v-model="deleteDialog"
      @handle-event="deleteDeliveryInfo"></general-dialog>
    <general-dialog
      :dialog="removeDialog"
      :options="removeOptions"
      v-model="removeDialog"
      @handle-event="removeFromCart"></general-dialog>
  </v-container>
</template>
<script>
import backendService from "../services/backend-service.js";
import eventBus from "@/js/event-bus.js";
import CashDialog from "@/components/CashDialog.vue";
import DeliveryInfoDialog from "@/components/DeliveryInfoDialog.vue";
import GeneralDialog from "@/components/GeneralDialog.vue";
import generalMixin from "@/js/general-mixin";
import Decimal from "decimal.js";
export default {
  name: "ShoppingCart",
  mixins: [generalMixin],
  components: {
    "cash-dialog": CashDialog,
    "delivery-info-dialog": DeliveryInfoDialog,
    "general-dialog": GeneralDialog,
  },
  data() {
    return {
      cashDialog: false,
      paymentType: {},
      deleteDialog: false,
      deleteOptions: {
        btnText: "Yes",
        message: "This action is not revertable!",
        title: "Are you sure you want to delete the info?",
      },
      deliveryInfoDialog: false,
      deliveryAddresses: [],
      isLoading: false,
      isSuccessful: false,
      payment: {
        amount: 0,
        referenceNumber: "",
      },
      order: {
        deliveryAddressId: 0,
        subtotal: 0,
        deliveryFee: 0,
      },
      orderId: null,
      toDelete: -1,
      toRemove: "",
      removeDialog: false,
      removeOptions: {
        btnText: "Yes",
        message: "",
        title: "Are you sure you want to remove the item?",
      },
      totalAmount: 0,
    };
  },
  created: function () {
    this.getDeliveryAddresses();
  },
  computed: {
    cart: {
      get() {
        return this.$store.getters["cart"];
      },
      set(val) {
        this.$store.commit("setCart", val);
      },
    },
    cartCount: {
      get() {
        return this.$store.getters["cartCount"];
      },
      set(val) {
        this.$store.commit("setCartCount", val);
      },
    },
    subtotal() {
      let sum = new Decimal(0);
      for (let key in this.cart) {
        let item = this.cart[key].product;
        let count = this.cart[key].count;
        sum = sum.plus(this.lineTotal(item.unitPrice, count));
      }
      return sum;
    },

    deliveryFee() {
      if (this.subtotal < 50) {
        return new Decimal(2);
      } else {
        return new Decimal(0);
      }
    },
    paymentTypes() {
      if (this.$store.getters["dropdownToOptions"]["paymentType"]) {
        return this.$store.getters["dropdownToOptions"]["paymentType"].options;
      }
      return [];
    },
    validDeliveryAddress() {
      if (this.order && this.order.deliveryAddressId) {
        return true;
      }
      return false;
    },
    validPayment() {
      if (this.paymentType && this.paymentType.strRef) {
        return true;
      }
      return false;
    },
    abaPayment() {
      if (this.paymentType && this.paymentType.strRef == "abaPay") {
        return true;
      }
      return false;
    },
    cashPayment() {
      if (this.paymentType && this.paymentType.strRef == "cash") {
        return true;
      }
      return false;
    },
    validSubmit() {
      return (
        (this.abaPayment && this.payment.referenceNumber.length < 5) ||
        !this.validDeliveryAddress ||
        this.cartCount == 0 ||
        !this.validPayment
      );
    },
  },
  methods: {
    deleteDeliveryInfo() {
      let id = this.deliveryInfoLst[this.toDelete].id;
      backendService.deleteDeliveryInfoById(id).then(() => {
        this.deliveryInfoLst.splice(this.toDelete, 1);
      });
    },
    getProductPictureUrlById(imageId) {
      if (!imageId) return;
      return backendService.getProductPictureUrlById(imageId);
    },
    lineTotal(unitPrice, count) {
      return new Decimal(count).times(new Decimal(unitPrice));
    },
    getDeliveryAddresses() {
      let userId = this.currentUser.id;
      this.timer(500).then(() => {
        if (!userId) {
          this.getDeliveryAddresses();
        } else {
          this.getDeliveryAddressesByUserId(userId);
        }
      });
    },
    getDeliveryAddressesByUserId(userId) {
      backendService.getDeliveryAddressesByUserId(userId).then((response) => {
        this.deliveryAddresses = response.data;
        for (let i = 0; i < this.deliveryAddresses.length; i++) {
          let deliveryAddress = this.deliveryAddresses[i];
          if (deliveryAddress.main) {
            this.order.deliveryAddressId = deliveryAddress.id;
          }
        }
      });
    },
    removeFromCart() {
      let cart = this.cart;
      let cartCount = this.cartCount - cart[this.toRemove].count;
      delete cart[this.toRemove];
      this.$store.commit("setCartCount", cartCount);
      this.$store.commit("setCart", cart);
      localStorage.setItem("cartCount", cartCount);
      localStorage.setItem("cart", JSON.stringify(cart));
    },
    setDeliveryInfoId(id) {
      this.order.deliveryAddressId = id;
      this.getDeliveryAddressesByUserId(this.currentUser.id);
    },
    openCashDialog() {
      this.totalAmount = this.subtotal.plus(this.deliveryFee);
      this.cashDialog = true;
    },
    submitOrder() {
      let orderDetails = [];
      for (let key in this.cart) {
        let val = this.cart[key];
        let orderDetail = {
          productId: val.product.id,
          storeId: val.product.storeId,
          quantity: val.count,
          unitPrice: val.product.unitPrice,
          serviceFee: val.product.serviceFee,
        };
        orderDetails.push(orderDetail);
      }
      this.payment.typeId = this.paymentType.id;
      this.payment.amount = this.subtotal.plus(this.deliveryFee);
      this.order.paymentInfo = this.payment;
      this.order.subtotal = this.subtotal;
      this.order.deliveryFee = this.deliveryFee;
      this.order.orderDetails = orderDetails;
      this.order.vatFee = 0;
      this.isLoading = true;
      backendService
        .addOrder(this.order)
        .then((response) => {
          let insertedOrder = response.data;
          this.timer(1000).then(() => {
            this.orderId = insertedOrder.id;
            this.isLoading = false;
            this.isSuccessful = true;
            this.paymentType = {};

            this.order = {
              deliveryAddressId: null,
              subtotal: 0,
              deliveryFee: 0,
            };
            this.$store.commit("setCart", {});
            this.$store.commit("setCartCount", 0);
            localStorage.setItem("cart", JSON.stringify({}));
            localStorage.setItem("cartCount", 0);
          });
        })
        .catch(() => {
          this.timer(1000).then(() => {
            this.isLoading = false;
            eventBus.$emit(
              "setSnackbar",
              "Some Items might be low in stock",
              "error",
              true
            );
          });
        });
    },
    updateItemCount(key) {
      let cart = this.cart;
      let newCount = 0;
      for (key in cart) {
        newCount += cart[key].count;
      }
      this.cartCount = newCount;

      localStorage.setItem("cartCount", newCount);
      localStorage.setItem("cart", JSON.stringify(cart));
    },
  },
};
</script>
<style scoped>
.row {
  background: white;
  border-width: 0 0 1px 0;
  border-style: solid;
  border-color: #dbdbdb;
}
.image-preview {
  width: 80px;
  height: 80px;
  margin-right: 10px;
  margin-bottom: 5px;
  margin-top: 5px;
  object-fit: contain;
  float: left;
  image-rendering: smooth;
}
.title {
  font-size: 14px;
  font-weight: bold;
}
.display-price {
  font-size: 20px;
  font-weight: bold;
}
</style>
