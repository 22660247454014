<template>
  <v-footer dark padless>
    <v-card flat tile class="blue darken-2 white--text" width="100%">
      <v-card-text class="d-flex justify-space-between">
        <div>
          <div class="my-2"><h3>Pay with</h3></div>
          <div>
            <i class="fab fa-cc-paypal mr-3" style="font-size: 30px"></i>
            <i class="fab fa-cc-visa mr-3" style="font-size: 30px"></i>
          </div>
        </div>
        <div>
          <div class="my-2">
            <h3>Stay Connected</h3>
          </div>

          <div>
            <i class="fab fa-facebook-square mr-3" style="font-size: 30px"></i>

            <i class="fab fa-instagram mr-3" style="font-size: 30px"></i>

            <i
              class="fab fa-facebook-messenger mr-3"
              style="font-size: 30px"></i>

            <i class="fab fa-telegram mr-3" style="font-size: 30px"></i>
          </div>
        </div>
      </v-card-text>

      <v-card-text class="white--text pt-0"> </v-card-text>

      <v-divider></v-divider>

      <v-card-text class="white--text text-center">
        <strong><i class="far fa-copyright"></i> Jaek Jay -</strong>
        {{ new Date().getFullYear() }}
      </v-card-text>
    </v-card>
  </v-footer>
</template>
<script>
export default {
  name: "MainFooter",
  props: {
    dialog: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    options: {
      type: Object,
      default: () => {
        return {
          btnText: "Text",
          message: "System Default",
          title: "System Default",
        };
      },
    },
  },
  data() {
    return { icons: [] };
  },
  created: function () {},

  computed: {
    value: {
      get() {
        return this.dialog;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  methods: {},
};
</script>
<style scoped></style>
