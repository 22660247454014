<template>
  <v-container fluid class="w-75">
    <v-container class="white rounded-xl">
      <v-container>
        <h2 class="blue--text">{{ idToOption[categoryId].displayName }}</h2>
      </v-container>

      <product-card
        v-for="product in products"
        :key="product.id"
        :product="product"
        allow-add-to-cart=""></product-card>
    </v-container>
  </v-container>
</template>
<script>
import backendService from "@/services/backend-service";
import eventBus from "@/js/event-bus";
//import GeneralSnackbar from "@/components/GeneralSnackbar.vue";
import ProductCard from "@/components/ProductCard.vue";
export default {
  name: "TypePage",
  mounted: function () {
    this.getProductsByTypeId(this.$route.params.id);
  },
  components: {
    //"general-snackbar": GeneralSnackbar,
    "product-card": ProductCard,
  },
  data() {
    return {
      products: [],
      store: {},
      isLoadingIdx: -1,
      isLoading: false,
    };
  },
  computed: {
    categoryId() {
      return this.$route.params.id;
    },
    cart() {
      return this.$store.getters["cart"];
    },
    cartCount() {
      return this.$store.getters["cartCount"];
    },
    idToOption() {
      return this.$store.getters["idToOption"];
    },
  },
  methods: {
    addToCart(product) {
      let cart = this.cart;
      let cartCount = this.cartCount;
      if (!cart[product.id]) {
        let item = { product: product, count: 0 };
        cart[product.id] = item;
      }
      cart[product.id].count += 1;
      this.$store.commit("setCart", cart);
      this.$store.commit("setCartCount", cartCount + 1);

      localStorage.setItem("cartCount", cartCount + 1);
      localStorage.setItem("cart", JSON.stringify(cart));
      this.isLoadingIdx = product.id;
      this.timer(1000).then(() => {
        this.isLoadingIdx = -1;
        eventBus.$emit(
          "setSnackbar",
          "Item added to the cart",
          "success",
          true
        );
      });
    },
    getProductsByTypeId(typeId) {
      backendService.getProductsByTypeId(typeId).then((response) => {
        this.products = response.data;
      });
    },
  },
};
</script>
