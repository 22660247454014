<template>
  <v-container fluid class="white rounded-xl mt-5">
    <h1>Stores</h1>

    <v-container
      v-if="stores.length < 1"
      class="grey lighten-2 rounded-xl text-center d-flex flex-column"
      ><h2>Nothing to See</h2>
      <i class="fas fa-spider"></i
    ></v-container>
    <v-container
      fluid
      v-for="(sublist, idxI) in stores"
      :key="`sublist-${idxI}`"
      class="d-flex"
      :class="{ 'justify-space-between': sublist.length == num }">
      <v-card
        v-for="(store, idxJ) in sublist"
        :key="`store-${idxI}-${idxJ}`"
        class="mt-5 rounded-xl"
        :class="{ 'mr-3': sublist.length < num }"
        elevation="0"
        outlined
        width="350px"
        @click="
          $router.push({
            name: 'StoreManagement',
            params: { id: store.id },
          })
        ">
        <v-img
          src="https://cdn.vuetifyjs.com/images/cards/sunshine.jpg"
          height="200px"></v-img
        ><v-card-title>{{ store.name }}</v-card-title></v-card
      >
    </v-container>
  </v-container>
</template>
<script>
export default {
  name: "StoreList",
  data() {
    return {
      num: 4,
    };
  },
  computed: {
    stores() {
      let stores = this.$store.getters["stores"];
      let formattedLst = [];
      let sublist;
      for (let i = 0; i < stores.length; i++) {
        if (i % this.num == 0) {
          sublist = [];
          formattedLst.push(sublist);
        }
        sublist.push(stores[i]);
      }
      return formattedLst;
    },
  },
};
</script>
<style scoped></style>
