import { render, staticRenderFns } from "./InStockForm.vue?vue&type=template&id=81e3f2dc&scoped=true&"
import script from "./InStockForm.vue?vue&type=script&lang=js&"
export * from "./InStockForm.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "81e3f2dc",
  null
  
)

export default component.exports